import { Option } from "../types";
export interface ITabList {
  value: string;
  label: string;
  enabled: boolean;
}

export const tablist: ITabList[] = [
  {
    value: "retail",
    label: "Retail",
    enabled: true,
  },
  {
    value: "corporate",
    label: "Corporate",
    enabled: false,
  },
  {
    value: "partner",
    label: "Partner",
    enabled: false,
  },
];

export const entityTypes = [
  { value: "firm", label: "Firm" },
  { value: "freelancer", label: "Freelancer" },
];

export const contactPersonTypes = [
  { value: "employee", label: "Employee" },
  { value: "coordinator", label: "Coordinator" },
];

export const campaignOptions = [
  { value: "", label: "--" },
  { value: "socialMedia", label: "Social Media" },
  { value: "email", label: "Email" },
  { value: "google", label: "Google" },
];

export const refferedOptions = [
  { value: "none", label: "Not referred" },
  { value: "partner", label: "Partner Referral" },
  { value: "staff", label: "Employee Referral" },
];

export interface FieldVisibility {
  company_name: boolean;
  company_email: boolean;
  contact_person_name: boolean;
  contact_person_phone: boolean;
  contact_person_type: boolean;
  customer_contact_name: boolean;
  customer_contact_email: boolean;
  customer_contact_phone: boolean;
  customer_contact_address: boolean;
  entityName: boolean;
  entityEmail: boolean;
  entityType: boolean;
  entityNumber: boolean;
  campaign: boolean;
  referralSource: boolean;
}

export const pipelineFeatureRules: Record<string, Partial<FieldVisibility>> = {
  corporate: {
    company_name: true,
    company_email: true,
    contact_person_name: true,
    contact_person_phone: true,
    contact_person_type: true,
    customer_contact_name: false,
    customer_contact_email: false,
    customer_contact_phone: false,
    customer_contact_address: false,
    entityName: false,
    entityEmail: false,
    entityType: false,
    entityNumber: false,
  },
  retail: {
    company_name: false,
    company_email: false,
    contact_person_name: false,
    contact_person_phone: false,
    contact_person_type: false,
    customer_contact_name: true,
    customer_contact_email: true,
    customer_contact_phone: true,
    customer_contact_address: true,
    entityName: false,
    entityEmail: false,
    entityType: false,
    entityNumber: false,
    campaign: true,
    referralSource: true,
  },
  partner: {
    company_name: false,
    company_email: false,
    contact_person_name: false,
    contact_person_phone: false,
    contact_person_type: false,
    customer_contact_name: false,
    customer_contact_email: false,
    customer_contact_phone: false,
    customer_contact_address: false,
    entityName: true,
    entityEmail: true,
    entityType: true,
    entityNumber: true,
  },
};

export const searchPlaceholderText: Record<string, string> = {
  retail: "Search customer with customer ID, name and number",
  corporate: "Search contact with company name or email",
  partner: "Search entity with name",
};

export const initOppType: Option[] = [
  { value: "callOutbound", label: "Outbound - Call" },
  { value: "callInbound", label: "Inbound - Call" },
  { value: "emailOutbound", label: "Outbound - Email" },
  { value: "emailInbound", label: "Inbound - Email" },
  { value: "meeting", label: "In Person Meeting" },
  { value: "visit", label: "Visit" },
  { value: "chatOutbound", label: "Outbound - Chat" },
  { value: "chatInbound", label: "Inbound - Chat" },
  { value: "socialMedia", label: "Social Media" },
  { value: "website", label: "Website" },
];

export const channelOptionInbound = [
  {
    label: "Call",
    value: "call",
  },
  {
    label: "Email",
    value: "email",
  },
  // {
  //   label: "Meeting",
  //   value: "meeting",
  // },
  {
    label: "Chat",
    value: "chat",
  },
];

export const channelOptionOutbound = [
  {
    label: "Walk-in",
    value: "walk-in",
  },
  {
    label: "Call",
    value: "call",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Chat",
    value: "chat",
  },
  {
    label: "Social Media",
    value: "social media",
  },
  {
    label: "Referral",
    value: "refferal",
  },
];

export const channel_options_outbound = [
  { label: "Call", value: 5 },
  { label: "Email", value: 6 },
  { label: "Walk-in", value: 7 },
  { label: "Chat", value: 8 },
  // { label: "Website", value: 9 },
  { label: "Social Media", value: 10 },
  // { label: "Refferal Online", value: 11 },
  { label: "Refferal", value: 12 },
];

export const channel_options_inbound = [
  { label: "Call", value: 1 },
  { label: "Email", value: 2 },
  { label: "Meeting", value: 3 },
  { label: "Chat", value: 4 },
];

export function formatDate(timestamp: string) {
  try {
    const date = new Date(parseInt(timestamp));
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${day}-${month}-${year} | ${hours}:${minutes} ${ampm}`;
  } catch (error) {
    return "Invalid date";
  }
}

export function getRelativeDate(timestamp: number): string {
  const date = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date();
  const tomorrow = new Date();

  yesterday.setDate(today.getDate() - 1);
  tomorrow.setDate(today.getDate() + 1);

  // Reset time part for comparison
  const compareDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  const compareToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );
  const compareYesterday = new Date(
    yesterday.getFullYear(),
    yesterday.getMonth(),
    yesterday.getDate(),
  );
  const compareTomorrow = new Date(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate(),
  );

  if (compareDate.getTime() === compareToday.getTime()) {
    return `Today | ${formatDate(timestamp.toString()).split("|")[1]}`;
  } else if (compareDate.getTime() === compareTomorrow.getTime()) {
    return `Tomorrow | ${formatDate(timestamp.toString()).split("|")[1]}`;
  } else if (compareDate.getTime() === compareYesterday.getTime()) {
    return `Yesterday | ${formatDate(timestamp.toString()).split("|")[1]}`;
  } else {
    return formatDate(timestamp.toString());
  }
}

// Usage example:
const timestamp = 1730276422142;
console.log(getRelativeDate(timestamp));

export function isValidIndianMobile(number: string) {
  // Remove all spaces and other characters
  const cleanNumber = number.toString().replace(/\D/g, "");
  const indianMobilePattern = /^(?:(?:\+91)|(?:91)|(?:0))?[6789]\d{9}$/;

  const isValid = indianMobilePattern.test(cleanNumber);

  const isValidLength = cleanNumber.length >= 10 && cleanNumber.length <= 13;

  return isValid && isValidLength;
}
