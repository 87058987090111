import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { fakeOffices, groupProducts, groups, tabs } from "../utils";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProducts } from "redux/features/CreateGroup";
import { useAppSelector } from "redux/hooks";
type ProductType = "remittance" | "forex" | "visa" | "travel";

interface SelectProductsProps {
  isEditing?: boolean;
}

export default function SelectProducts({
  isEditing = false,
}: SelectProductsProps) {
  const [activeProduct, setActiveProduct] = useState<ProductType>("remittance");
  const dottedBorderWidth = "after:h-20 after:border-blue-700";
  const state = useAppSelector((state) => state.CreateGroups);
  const dispatch = useDispatch();

  const handleCheckboxChange = (product: { value: string; label: string }) => {
    const currentProducts = state.products[activeProduct];
    const isSelected = currentProducts.some((p) => p.id === product.value);

    let updatedProducts;
    if (isSelected) {
      updatedProducts = currentProducts.filter((p) => p.id !== product.value);
    } else {
      updatedProducts = [
        ...currentProducts,
        { id: product.value, name: product.label },
      ];
    }

    dispatch(
      setProducts({
        name: activeProduct,
        products: updatedProducts,
      }),
    );
  };
  const getTabCount = (tabValue: ProductType) => {
    return state.products[tabValue]?.length || 0;
  };
  return (
    <form className="flex flex-col gap-6" onSubmit={() => {}}>
      {!isEditing && (
        <p className=" text-sm leading-[18.2px] pl-10 sm:pl-0 font-medium text-[#999999]">
          Group members will have{" "}
          <span className="text-black-label">
            {groups.find((item) => item.value === state.role)?.label}
          </span>{" "}
          access in{" "}
          <span className="text-black-label">
            {state.offices.find((item) => item.value === state.office)?.label}
          </span>{" "}
          for these products
        </p>
      )}
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <Tabs
            className="flex rounded-lg gap-2"
            value={activeProduct}
            onValueChange={(value) => setActiveProduct(value as ProductType)}
          >
            <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
              {tabs.map((tab: { label: string; value: string }) => (
                <TabsTrigger
                  key={tab.value}
                  value={tab.value}
                  className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === activeProduct ? " bg-navbar-menu-bg text-blue-700" : " text-black-label bg-[#F1F1F1]"}`}
                >
                  {tab.label}{" "}
                  {getTabCount(tab.value as ProductType) > 0 &&
                    `(${getTabCount(tab.value as ProductType)})`}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          {
            <div className="flex flex-col gap-2.5 w-full max-w-[340px]">
              <p className="text-sm">Select one or more</p>
              {groupProducts[activeProduct].map(
                (product: { value: string; label: string }) => {
                  const isChecked = state.products[activeProduct]?.some(
                    (p) => p.id === product.value,
                  );
                  return (
                    <div
                      key={product.value}
                      style={{
                        borderColor: isChecked ? "#165FE3" : "#C4C4C4",
                      }}
                      className="flex items-center gap-2.5 border py-3.5 px-2.5  w-full rounded-md"
                    >
                      <Checkbox
                        style={{
                          borderColor: isChecked ? "#165FE3" : "#C4C4C4",
                        }}
                        className="h-5 w-5 border border-[#C4C4C4] rounded"
                        checked={isChecked}
                        onCheckedChange={() => handleCheckboxChange(product)}
                        id={product?.label}
                      />
                      <label
                        htmlFor={product?.label}
                        className="text-sm font-medium select-none leading-[19.94px] peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {product?.label}
                      </label>
                    </div>
                  );
                },
              )}
            </div>
          }
        </div>
      </div>
    </form>
  );
}
