import React, { useEffect } from "react";
import { Button, Img, Input, SelectBox, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  GetInfoRequestType,
  GetPDFURLRequestType,
  GetRemittancePDFURLRequestType,
  getInfo,
  getRemittancePDFURL,
} from "service/apis/CreateForexQuotation";
import { formatIndianNumber } from "utils";
import {
  postCorporateInteractions,
  PostCorporateInteractionsReqType,
  putUpdateInteractionStatus,
  PutUpdateInteractionStatusReqType,
} from "service/apis/Opportunities";
import { toast } from "sonner";
import { NewStateLocation } from "pages/CreateRemittanceQuotation/step1";

const CustomerDetails: React.FC<any> = ({
  setConfirmCancel,
  state,
  generate,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [managingBranch, setManagingBranch] = React.useState<number>(0);

  const [customerName, setCustomerName] = React.useState<string>("");
  const [customerNumber, setCustomerNumber] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);
  const [partnerName, setPartnerName] = React.useState<string>("");
  const mystate = { ...(state as NewStateLocation) };

  React.useEffect(() => {
    getDetails();
    setCustomerName(mystate?.contact_name);
    setCustomerNumber(mystate?.contact_number);
  }, []);

  const {
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    cal_profit,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    tcs,
    fx_charges,
    partnerDeal,
  } = useAppSelector((state) => state.CreateDeal);

  const navigate = useNavigate();

  const getDetails = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetInfoRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    getInfo(payload)
      .then((res) => {
        setPartnerName(res?.data?.name);
      })
      .catch((err) => {});
  };

  const handleUpdateInteractionStatus = async (pdf_url: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id: mystate?.interaction_id as string,
        isOpen: "false",
        quotation_pdf: pdf_url
      },
    };
    try {
      const res = (await putUpdateInteractionStatus(payload)) as any;
      if (res.status === 200) {
        `Interaction added successfully for Lead-ID ${mystate?.opportunity_id}`;
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update interaction status");
      setLoading(false);
    }
  };

  const handleAddInteraction = async (pdfUrl: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const mystate = { ...(state as NewStateLocation) };

    if (!mystate?.opportunity_id) {
      setLoading(false);
      return;
    }

    const payload: AxiosRequestConfig<PostCorporateInteractionsReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        opportunity_id: mystate?.opportunity_id as string,
        remarks: "",
        meeting_time: mystate?.meeting_time,
        meetingStaff_id: mystate?.meetingStaff_id,
        note: mystate?.note,
        channel_of_interaction_id: mystate?.channel_of_interaction_id as string,
        quotation_pdf: pdfUrl,
      },
    };
    try {
      const res = (await postCorporateInteractions(payload)) as any;
      if (res.status === 200) {
        toast.success(
          `Interaction added successfully for id ${mystate?.opportunity_id}`,
        );
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to add interaction");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (customerName == "" || customerName == "") {
      setShowError(true);
      return;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const todayDate = new Date();
    // Calculate the date after 7 days
    const today = new Date(todayDate);
    today.setDate(today.getDate());

    // Format the date in "dd/mm/yy" format
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    const mystate = { ...(state as NewStateLocation) };

    const payload: AxiosRequestConfig<GetRemittancePDFURLRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        data: {
          date: formattedDate,
          customer_name: mystate?.contact_name,
          customer_number: mystate?.contact_number,
          purpose: purpose_id,
          currency: currency,
          remitter_send:
            "₹ " +
            formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2)),
          beneficiary_received:
            (currency === "" || currency === undefined ? "USD" : currency) +
            " " +
            formatIndianNumber(parseFloat(amount)?.toFixed(4)),
          customer_rate:
            cal_customer_rate == "" ? "0.00" : "₹ " + cal_customer_rate,
          total_charges:
            "₹ " + parseFloat(Number(cal_total_charges)?.toFixed(2)),
          gst: "₹ " + Number(cal_gst)?.toFixed(2),
          fb_charges: "₹ " + Number(cal_foreign_bank_charges)?.toFixed(2),
          tt_charges: "₹ " + Number(cal_tt_charges)?.toFixed(2),
          payable_amount:
            "₹" + formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2)),
          partner_name: partnerName,
        },
      },
    };

    setLoading(true);
    getRemittancePDFURL(payload)
      .then((res) => {
        setLoading(false);
        if (res?.data === "") {
          toast.error("Failed to generate PDF");
          return;
        } else {
          if(mystate?.interaction_id) {
            handleUpdateInteractionStatus(res?.data as string).then(() => {});
          }
          else{
            handleAddInteraction(res?.data as string).then(() => {});
          }
          downloadPDFfromUrl(res?.data);
          navigate("/remit/leads");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const downloadPDFfromUrl = async (pdf_url: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Remittance Quotation.pdf";

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
      setConfirmCancel(false);
    } catch (error) {
      // console.error('Error downloading PDF:', error);
    }
  };
  

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] sm:w-[300px] w-[550px] py-[40px] px-[31px] sm:px-[10px] sm:py-[40px]">
        <div className="flex flex-col w-full gap-[20px]">
          <div className="flex flex-col gap-[20px] w-full">
            <div className="flex flex-row w-full justify-start">
              <Text
                className="text-[#111827] font-[700] text-[16px] leading-[24px]"
                size="txtInterSemiBold16"
              >
                Update Customer Details
              </Text>
            </div>

            <div className="flex flex-col justify-start w-full gap-[8px]">
              <Text className="opacity-87 w-full" size="txtInterRegular14">
                Customer name
              </Text>
              <Input
                name="name"
                value={mystate?.contact_name}
                errors={
                  customerName === "" && showError
                    ? ["Please Enter Customer Name"]
                    : [""]
                }
                placeholder="Enter Customer Name"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col justify-start w-full gap-[8px]">
              <Text className="opacity-87 w-full" size="txtInterRegular14">
                Customer Number
              </Text>
              <Input
                name="name"
                value={mystate?.contact_number}
                errors={
                  customerNumber === "" && showError
                    ? ["Please Enter Customer Number"]
                    : [""]
                }
                type="number"
                placeholder="Enter Customer Number"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
          </div>

          <div className="flex flex-row sm:gap-[] sm:gap-x-[] sm:gap-y-[] items-center justify-end bottom-0 sm:justify-end w-full gap-[12px]">
            <Text
              className="common-pointer text-base sm:text-[16px] px-[14px] py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => setConfirmCancel(false)}
            >
              Cancel
            </Text>
            <Button
              onClick={() => handleSubmit()}
              className="bg-blue-700 cursor-pointer font-inter px-[14px] py-[10px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            >
              Download
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default CustomerDetails;
