import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Img,
  Input,
  List,
  SelectBox,
  Text,
  Button,
  ReactTable,
  Spinner,
} from "components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import {
  GetPartnerDetailsRequestType,
  getPartnerDetails,
  PutOrderStatusRequestType,
  putOrderStatus,
  CapturePaymentRequestType,
  capturePayment,
} from "service/apis/Orderdetails";
import SettlementRate from "models/OrderDetails/SettlementRate";
import { formatCurrency, formatIndianNumber } from "utils";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { AiOutlineCloseCircle } from "react-icons/ai";
import imgsvg from "../../assets/images/icon_download.svg";
import AddDeclaration from "models/OrderDetails/CapturePayment";
import { toast, Toaster } from "sonner";
import UploadDocuments from "models/OrderDetails/CaptureDocuments";
import { useEffect, useMemo, useState } from "react";
import { order_status_options } from "common/filters";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PaymentType } from "@/lib/validations/schema";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { useForm } from "react-hook-form";
import { convertFileToBase64 } from "pages/CreateRemit/payment";
const inProgressOptionsList = [
  { label: "Fulfilled", value: "fulfilled" },
  { label: "Cancel", value: "cancelled" },
  { value: "pre processing", label: "Pre Processing" },
  { value: "processing", label: "Processing" },
];
type PaymentTableRowType = {
  id: string;
  acknowledgement_no: string;
  amount: string;
  receipt: {
    url: string;
  };
};
type OrderdetailsType = {
  id: string;
  status: string;
  payment_status: string;
  created_at: string;
  _order_customers: {
    full_name: string;
    email: string;
    phone: string;
    pan_no: string;
    aadhaar_no: string;
    pan_soft_copy: {
      url: string;
    };
    aadhaar_soft_copy_front: {
      url: string;
    };
  };
  supporting_documents: [];
  _order_channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address: string;
  };
  amount: number;
  currency: string;
  gst: number;
  tcs: number;
  tt_charges_inclgst: number;
  foreign_bank_rates_inclgst: number;
  payable_amount: number;
  l1_commission_rates: number;
  l2_commission_rates: number;
  l3_commission_rates: number;
  l1_channel: number;
  l2_channel: number;
  l3_channel: number;
  service_partner_rates: number;
  eforex_rates: number;
  _payments_of_order: {
    id: string;
    acknowledgement_no: string;
    amount: string;
    receipt?: {
      url: string;
    };
  }[];
  _order_service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
  swift_document: {
    url: string;
  };
  invoice_document: {
    url: string;
  };
};

const NewOrderDetailsPage: React.FC = () => {
  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id } = useParams();
  const [orderdetails, setOrderDetails] = useState<OrderdetailsType>();
  const [createdate, setCreateDate] = useState<any>();
  const [showcapturepayment, setShowCapturePayment] = useState(false);
  const [showUploadDocumentType, setShowUploadDocumentType] = useState("");
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [showimg, setShowimg] = useState(false);
  const [imgsrc, setImgsrc] = useState("");
  const [orderstatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState<number>();
  const [activeTab, setTab] = useState<string>("overview");
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    OrderDetails();
  }, [id, shouldRefetch]);

  useEffect(() => {
    if (orderdetails) {
      const date = new Date(orderdetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }

    const amount = orderdetails?._payments_of_order.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.amount),
      0,
    );
    setRemainingAmount(Number(orderdetails?.payable_amount) - Number(amount));
  }, [orderdetails]);

  // useEffect(() => {
  //   if (orderstatus) {
  //     handleonorderstatuschange();
  //   }
  // }, [orderstatus]);

  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");
  const orderChildLevel =
    orderdetails?.l2_channel == 0
      ? "1"
      : orderdetails?.l3_channel == 0
        ? "2"
        : "3";

  const OrderDetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetPartnerDetailsRequestType> = {
      data: {
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getPartnerDetails(payload)
      .then((res: any) => {
        console.log(res.data);
        setOrderDetails(res.data?.result_1);
        setPaymentData(
          res.data?.result_1?._payments_of_order.map(
            (payment: any, index: number) => ({
              id: index + 1,
              acknowledgement: payment.acknowledgement_no,
              createdBy: "N/A",
              creationDate: new Date().toDateString(),
              amount: parseInt(payment.amount),
            }),
          ) || [],
        );
        if (res.data.result_1.status) {
          setOrderStatus(res.data?.result_1.status);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleonorderstatuschange = (e: string): void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutOrderStatusRequestType> = {
      data: {
        status: e,
        order_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    putOrderStatus(payload)
      .then((res: any) => {
        setLoading(false);
        setOrderStatus(res.data.status);
        OrderDetails();
        toast.success("Order Status Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const paymentTableColumns: any = useMemo(() => {
    const tableColumnsHelper = createColumnHelper<PaymentTableRowType>();
    return [
      tableColumnsHelper.accessor("id", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info.row.index + 1}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Ref ID
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("acknowledgement_no", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Acknowledgement No.
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("amount", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {formatIndianNumber(info?.getValue())}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Amount
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("receipt", {
        cell: (info) => (
          <div
            onClick={() => {
              setImgsrc(info?.getValue()?.url);
              setShowimg(!showimg);
            }}
            className="cursor-pointer flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              View
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Receipt
            </Text>
          </div>
        ),
      }),
    ];
  }, []);

  const downloadFilefromUrl = async (pdf_url: string, name: string) => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
    } catch (error) {
      // console.error('Error downloading File:', error);
    }
  };

  const status = orderdetails?.payment_status;
  let paymentborderColor;
  switch (status) {
    case "pending":
      paymentborderColor = "bg-[#FFA500]"; // Gray
      break;
    case "partially recieved":
      paymentborderColor = "bg-[#0000FF]"; // Light Green
      break;
    case "recieved":
      paymentborderColor = "bg-[#008000]"; // Green
      break;
    default:
  }
  let statusbgcolor = orderstatus;
  switch (statusbgcolor) {
    case "pending":
      statusbgcolor = "bg-[#808080]"; // Gray
      break;
    case "processing":
      statusbgcolor = "bg-[#32CD32]"; // Light Green
      break;
    case "fulfilled":
      statusbgcolor = "bg-colors1"; // Green
      break;
    case "pre processing":
      statusbgcolor = "bg-[#888888]";
      break;
    case "cancelled":
      statusbgcolor = "bg-[#FF0000]";
      break;
    default:
  }

  const getPaymentStatus = (status: string): string => {
    if (status === "recieved") {
      return "received";
    } else if (status === "partially recieved") {
      return "partially received";
    }
    return status;
  };

  const tabsdata = [
    {
      id: 1,
      label: "Overview",
      value: "overview",
      content: "Make changes to your account here.",
    },
    {
      id: 2,
      label: "Amount & Charges",
      value: "amount-charges",
      content: "Change your password here.",
    },
    {
      id: 3,
      label: "Documentations",
      value: "documents",
      content: "Change your password here.",
    },
    {
      id: 4,
      label: "Payments",
      value: "payments",
      content: "Change your password here.",
    },
    {
      id: 5,
      label: "Other Details",
      value: "other-details",
      content: "Change your password here.",
    },
  ];
  const columns: ColumnDef<PaymentType>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Ref ID" />
      ),
    },
    {
      accessorKey: "acknowledgement",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Acknowledgement" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "createdBy",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created By" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "creationDate",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date Created" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "Receipt",
      cell: ({ row }) => {
        return (
          <Link className="text-center w-full" to={"#"}>
            <button className="rounded-md py-2 px-4 border border-black-900 font-semibold text-sm">
              Download
            </button>
          </Link>
        );
      },
    },
  ];
  const labelStyle = "font-medium text-[#666666] text-sm leading-[21px]";
  const valueStyle = "font-medium text-[#333333] text-sm leading-[21px]";

  const onTabChange = (value: string) => {
    setTab(value);
  };

  const onSaveClick = () => {
    const formData = getValues();
    convertFileToBase64(formData["supporting-document"][0])
      .then((base64String) => {
        handleCapturePayment(formData, base64String)
          .then((res) => {
            setShouldRefetch(!shouldRefetch);
            reset();
            setSelectedFile(null);
            console.log(res);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error("Error converting file to base64:", error);
      });
  };

  const handleCapturePayment = async (formData: any, file: string) => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        acknowledgement_no: formData.acknowledgement_no,
        amount: formData.amount,
        receipt: file,
        order_id: id,
      } as CapturePaymentRequestType,
    };
    try {
      await capturePayment(payload);
      toast.success("Payment Captured Successfully");
      setDialogOpen(false);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSwiftDocument = () => {
    if (
      orderdetails?.status === "fulfilled" &&
      orderdetails?.swift_document?.url
    ) {
      downloadFilefromUrl(orderdetails?.swift_document.url, "swift_document");
      return;
    }
    setShowUploadDocumentType("SWIFT");
  };

  const handleInvoiceDocument = () => {
    if (
      orderdetails?.status === "fulfilled" &&
      orderdetails?.invoice_document?.url
    ) {
      downloadFilefromUrl(
        orderdetails?.invoice_document.url,
        "invoice_document",
      );
      return;
    }
    setShowUploadDocumentType("Invoice");
  };
  console.log(orderdetails?.payable_amount);
  return (
    <>
      {loading && <Spinner />}
      {
        <div>
          {showcapturepayment && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <AddDeclaration
                orderID={id}
                setShowCapturePayment={setShowCapturePayment}
                OrderDetails={OrderDetails}
                remainingAmount={remainingAmount}
              />
            </div>
          )}
          {showUploadDocumentType !== "" && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
            >
              <UploadDocuments
                showUploadDocumentType={showUploadDocumentType}
                orderID={id}
                setShowUploadDocumentType={setShowUploadDocumentType}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                orderdetails={orderdetails}
                OrderDetails={OrderDetails}
              />
            </div>
          )}
        </div>
      }

      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <div
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </div>
        </div>
      )}

      <div className="flex w-full flex-col gap-5 py-5 px-6 sm:px-3 h-full">
        <div className=" flex flex-col w-full gap-8 border-b-2 pb-5">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Order
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Comprehensive details of Order-{id}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div className="py-2 px-3 bg-black-900 rounded-lg w-fit">
              <div className="flex gap-2">
                <p className="text-white-A700 bg-black-900 ">
                  Order ID:{" "}
                  <span className="font-medium text-[18px] leading-[14px]">
                    {orderdetails?.id}
                  </span>
                </p>
                <p
                  style={{
                    backgroundColor: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.bgColor,
                    color: order_status_options.find(
                      (status) => orderdetails?.status === status.value,
                    )?.textColor,
                  }}
                  className=" capitalize py-[4px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
                >
                  {orderdetails?.status}
                </p>
              </div>
            </div>
            {level === "1" &&
              (orderdetails?.payment_status === "recieved" ||
                orderdetails?.status === "fulfilled") && (
                <div className="flex gap-3">
                  <Button
                    onClick={handleSwiftDocument}
                    className="rounded-lg py-[11px] flex gap-2 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
                  >
                    {orderdetails?.status === "fulfilled" &&
                    orderdetails?.swift_document?.url
                      ? "Download SWIFT"
                      : "Upload SWIFT"}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.95801 5.62533C3.95801 3.78438 5.45039 2.29199 7.29134 2.29199H11.9202C12.1564 2.29199 12.3816 2.39226 12.5396 2.56786L15.8274 6.22097C15.9652 6.37399 16.0413 6.57257 16.0413 6.77844V14.3753C16.0413 16.2162 14.5489 17.7087 12.708 17.7087H7.29134C5.45039 17.7087 3.95801 16.2162 3.95801 14.3753V5.62533Z"
                        fill="white"
                      />
                      <path
                        d="M16.042 7.29199V6.77844C16.042 6.57257 15.9658 6.37399 15.8281 6.22097L12.5402 2.56786C12.3822 2.39226 12.1571 2.29199 11.9208 2.29199H11.042V3.95866C11.042 5.79961 12.5344 7.29199 14.3753 7.29199H16.042Z"
                        fill="#98BDFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.07173 11.6278C7.32313 11.3913 7.71868 11.4033 7.95521 11.6548L10 13.8282L12.0448 11.6548C12.2813 11.4033 12.6768 11.3913 12.9282 11.6278C13.1797 11.8643 13.1917 12.2598 12.9552 12.5113L10.7077 14.9001C10.3215 15.3107 9.6785 15.3107 9.29225 14.9001L7.0448 12.5113C6.80827 12.2598 6.82033 11.8643 7.07173 11.6278Z"
                        fill="#165FE3"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 7.70801C10.3452 7.70801 10.625 7.98783 10.625 8.33301V14.583C10.625 14.9282 10.3452 15.208 10 15.208C9.65483 15.208 9.375 14.9282 9.375 14.583V8.33301C9.375 7.98783 9.65483 7.70801 10 7.70801Z"
                        fill="#165FE3"
                      />
                    </svg>
                  </Button>
                  <Button
                    onClick={handleInvoiceDocument}
                    className="rounded-lg py-[11px] px-4 border text-blue-700 border-blue-700 flex gap-2 font-semibold text-sm"
                  >
                    {orderdetails?.status === "fulfilled" &&
                    orderdetails?.invoice_document?.url
                      ? "Download Invoice"
                      : "Upload Invoice"}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.95801 5.62533C3.95801 3.78438 5.45039 2.29199 7.29134 2.29199H11.9202C12.1564 2.29199 12.3816 2.39226 12.5396 2.56786L15.8274 6.22097C15.9652 6.37399 16.0413 6.57257 16.0413 6.77844V14.3753C16.0413 16.2162 14.5489 17.7087 12.708 17.7087H7.29134C5.45039 17.7087 3.95801 16.2162 3.95801 14.3753V5.62533Z"
                        fill="#165FE3"
                      />
                      <path
                        d="M16.042 7.29199V6.77844C16.042 6.57257 15.9658 6.37399 15.8281 6.22097L12.5402 2.56786C12.3822 2.39226 12.1571 2.29199 11.9208 2.29199H11.042V3.95866C11.042 5.79961 12.5344 7.29199 14.3753 7.29199H16.042Z"
                        fill="#98BDFF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.07173 11.6278C7.32313 11.3913 7.71868 11.4033 7.95521 11.6548L10 13.8282L12.0448 11.6548C12.2813 11.4033 12.6768 11.3913 12.9282 11.6278C13.1797 11.8643 13.1917 12.2598 12.9552 12.5113L10.7077 14.9001C10.3215 15.3107 9.6785 15.3107 9.29225 14.9001L7.0448 12.5113C6.80827 12.2598 6.82033 11.8643 7.07173 11.6278Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 7.70801C10.3452 7.70801 10.625 7.98783 10.625 8.33301V14.583C10.625 14.9282 10.3452 15.208 10 15.208C9.65483 15.208 9.375 14.9282 9.375 14.583V8.33301C9.375 7.98783 9.65483 7.70801 10 7.70801Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                </div>
              )}
          </div>
        </div>
        <Tabs
          value={activeTab}
          onValueChange={onTabChange}
          className=" sm:max-w-7xl sm:overflow-hidden"
        >
          <TabsList className="bg-navbar-menu-bg w-full hide-scrollbar overflow-x-auto flex justify-between rounded-xl gap-2 py-3.5">
            {tabsdata.map((tab) => (
              <TabsTrigger
                key={tab.id}
                value={tab.value}
                className={`px-4 text-sm ${tab.value === activeTab && "border font-semibold border-blue-700 text-blue-700 w-[215px] rounded-lg shadow bg-white-A700"}`}
              >
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent
            className="p-3 gap-10 flex flex-col rounded-xl w-full overflow-auto"
            value="overview"
          >
            <div className="pb-10 border-b w-full flex flex-col gap-8">
              <div className="flex items-center w-full">
                {/* <div className="py-2 px-3  rounded-lg w-fit">
                  <div className="flex gap-2">
                    <p
                      style={{ backgroundColor: "#F3FFF7", color: "#00A82D" }}
                      className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                    >
                      <Img src="/images/orderdetails/green-circle.svg" />
                      Fullfilled
                    </p>
                  </div>
                </div> */}
                <div className="py-2 px-3 rounded-lg w-fit sm:w-full">
                  <div className="flex sm:w-full gap-2">
                    <p
                      style={{ backgroundColor: "#EBF1FB", color: "#165FE3" }}
                      className="py-2 text-sm leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl capitalize"
                    >
                      <Img src="/images/orderdetails/blue-circle.svg" />
                      Processing
                    </p>
                  </div>
                </div>
                <div className="sm:w-full">
                  <svg
                    width="144"
                    height="3"
                    viewBox="0 0 144 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="144"
                      y="2.5"
                      width="144"
                      height="2"
                      rx="1"
                      transform="rotate(180 144 2.5)"
                      fill="url(#paint0_linear_1105_32921)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1105_32921"
                        x1="144"
                        y1="3.5"
                        x2="288"
                        y2="3.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset="0.467887"
                          stop-color={
                            orderdetails?.status === "processing"
                              ? "#999999"
                              : order_status_options.find(
                                  (status) =>
                                    orderdetails?.status === status.value,
                                )?.textColor
                          }
                        />
                        <stop offset="0.534294" stop-color="#165FE3" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="py-2 px-3  rounded-lg w-fit sm:w-full">
                  <div
                    className={`flex sm:w-full gap-2 ${orderdetails?.status === "processing" ? "grayscale" : ""}`}
                  >
                    <p
                      style={{
                        backgroundColor: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.bgColor,
                        color: order_status_options.find(
                          (status) => orderdetails?.status === status.value,
                        )?.textColor,
                      }}
                      className="py-2 text-sm sm:w-full leading-4 font-medium flex items-center gap-2 px-3 text-center rounded-xl"
                    >
                      <Img
                        src="/images/orderdetails/green-circle.svg"
                        className={
                          orderdetails?.status !== "processing"
                            ? "grayscale"
                            : ""
                        }
                      />
                      {orderdetails?.status === "processing"
                        ? "Fullfilled"
                        : orderdetails?.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-8 w-full">
              <div className="flex w-2/3 flex-col gap-5">
                <div className="bg-[#FCFCFC] p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.22457 3.71649C6.76715 3.12836 6.05276 2.75 5.25 2.75C3.86929 2.75 2.75 3.86929 2.75 5.25C2.75 6.06385 3.13889 6.78687 3.74097 7.24337"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.7734 3.7195C17.2307 3.12966 17.9461 2.75 18.7503 2.75C20.131 2.75 21.2503 3.86929 21.2503 5.25C21.2503 6.06148 20.8637 6.78265 20.2646 7.23937"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 8V12L9 15"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 21.25L7 20M18 21.25L17 20"
                        stroke="#165FE3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <p className="">Created at</p>
                        <p className="">
                          {new Date(
                            orderdetails?.created_at as string,
                          ).toDateString()}
                        </p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">Created by</p>
                        <Link to="#" className="text-blue-700">
                          {orderdetails?._order_channels.name} &#8599;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-[#FCFCFC] p-5 flex flex-col gap-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/copy.svg" />
                        <p className="text-blue-700">Document Uploaded</p>
                      </div>
                      <div className="flex pl-10 gap-1">
                        <span className="font-medium text-[40px] leading-9 text-blue-700">
                          {orderdetails?.supporting_documents.length}
                        </span>
                        <span className="font-medium text-xl leading-6 text-[#98BDFF] flex items-end">
                          / {orderdetails?.supporting_documents.length}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={() => setTab("documents")}
                    className=" underline text-blue-700 text-right w-full"
                  >
                    View details
                  </Link>
                </div>
              </div>
              <div className="flex flex-wrap gap-5 flex-auto w-full">
                <div className="border-gray-border-gray-lite border flex flex-grow p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5 w-full">
                        <Img src="/images/orderdetails/house.svg" />
                        <p className="text-blue-700">Remitter</p>
                        <p className="text-blue-700">&#8599;</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">Name</p>
                        <p className="">
                          {orderdetails?._order_customers?.full_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/paperplane.svg" />
                        <p className="text-blue-700">Purpose</p>
                      </div>
                      <div className="flex text-blue-700 gap-5">N/A</div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/percent.svg" />
                        <p className="text-blue-700">FCY Amount</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">
                          {orderdetails?.amount &&
                            formatCurrency(
                              parseInt(orderdetails?.amount.toFixed(0)),
                              orderdetails?.currency as string,
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border flex flex-grow  p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/piechart.svg" />
                        <p className="text-blue-700">Balance</p>
                      </div>
                      <div className="flex gap-5">
                        <p className="">
                          {orderdetails?.payable_amount &&
                            formatCurrency(
                              parseInt(orderdetails?.payable_amount.toFixed(0)),
                              "INR",
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-gray-border-gray-lite border w-full flex-col flex p-5 rounded-[20px]">
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-5">
                      <div className="flex gap-5">
                        <Img src="/images/orderdetails/badgedollar.svg" />
                        <p className="">Total amount payable</p>
                      </div>
                      <div className="flex text-blue-700 gap-5">
                        <p className="">
                          {orderdetails?.payable_amount &&
                            formatCurrency(
                              parseInt(orderdetails?.payable_amount.toFixed(0)),
                              "INR",
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={() => setTab("amount-charges")}
                    className=" underline text-blue-700 text-right w-full"
                  >
                    View details
                  </Link>
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="amount-charges">
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
              <p className="text-[#666666] leading-4 font-semibold">
                Order Summary
              </p>
              <div className="pt-5 pb-10 px-5 rounded-xl shadow-order-summary gap-10">
                <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-between w-full">
                      <span className="text-sm indent-3.5 text-[#666666]">
                        FX Amount
                      </span>
                      <div>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            orderdetails?.amount as number,
                            orderdetails?.currency as string,
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between w-full">
                      <span className="text-sm indent-3.5 text-[#666666]">
                        Total Charges
                      </span>
                      <div className="text-sm font-medium">
                        <span>
                          {formatCurrency(
                            parseFloat(
                              Number(
                                Number(orderdetails?.gst) +
                                  Number(orderdetails?.tcs) +
                                  Number(orderdetails?.tt_charges_inclgst) +
                                  Number(
                                    orderdetails?.foreign_bank_rates_inclgst,
                                  ),
                              )?.toFixed(4),
                            ),
                            "INR",
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="w-full border-[.3px]" />
                    <div className="flex flex-col w-full gap-4">
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          GST on FX conversion
                        </span>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(orderdetails?.gst?.toFixed(2) as string),
                            "INR",
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          TCS{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(orderdetails?.tcs?.toFixed(2) as string),
                            "INR",
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          TT charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        <span className="text-sm font-medium">
                          {formatCurrency(
                            Number(
                              orderdetails?.tt_charges_inclgst?.toFixed(
                                2,
                              ) as string,
                            ),
                            "INR",
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-sm indent-3.5 text-[#98BDFF]">
                          Discount <span className="text-xs">%</span>
                        </span>
                      </div>
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-sm indent-3.5 text-[#666666]">
                          Foreign Bank charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        <span className="text-sm font-medium">
                          {orderdetails?.foreign_bank_rates_inclgst?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                    <span className="text-md indent-3.5 font-semibold">
                      Total amount payable
                    </span>
                    <div className="flex gap-1 items-center ">
                      <span className="text-md font-medium">
                        {formatCurrency(
                          parseInt(
                            orderdetails?.payable_amount.toFixed(2) as string,
                          ),
                          "INR",
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </TabsContent>
          <TabsContent value="documents">
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-5 flex flex-col">
              <p className="text-[#999999] leading-4 font-medium text-base">
                Documentation
              </p>
              <div className="w-full">
                <div className="grid grid-cols-2 gap-x-10 gap-y-4">
                  {orderdetails?.supporting_documents?.map(
                    (doc: { filename: string }, key: number) => (
                      <div
                        key={key}
                        className="flex flex-nowrap flex-auto gap-4 w-auto items-center"
                      >
                        <span className="font-medium">
                          {doc?.filename.split(".")[0]}:
                        </span>
                        <div className="flex-1 flex gap-5 items-center justify-end">
                          <span className="text-gray-600">{doc?.filename}</span>
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.83594 4.32812H3.0026C2.56058 4.32813 2.13665 4.50372 1.82409 4.81628C1.51153 5.12884 1.33594 5.55276 1.33594 5.99479V13.4948C1.33594 13.9368 1.51153 14.3607 1.82409 14.6733C2.13665 14.9859 2.56058 15.1615 3.0026 15.1615H10.5026C10.9446 15.1615 11.3686 14.9859 11.6811 14.6733C11.9937 14.3607 12.1693 13.9368 12.1693 13.4948V12.6615"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3372 2.66676L13.8372 5.16676M14.9914 3.98759C15.3196 3.65938 15.504 3.21424 15.504 2.75009C15.504 2.28594 15.3196 1.84079 14.9914 1.51259C14.6632 1.18438 14.2181 1 13.7539 1C13.2898 1 12.8446 1.18438 12.5164 1.51259L5.50391 8.50009V11.0001H8.00391L14.9914 3.98759Z"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </section>
          </TabsContent>
          <TabsContent value="payments">
            <section className="bg-white-A700 px-3 py-5 rounded-xl gap-5 flex flex-col">
              <div className="flex justify-between">
                <div className="flex gap-5 items-center">
                  <p className="leading-4 font-semibold">Payments</p>
                  <p
                    style={{
                      backgroundColor: order_status_options.find(
                        (status) => orderdetails?.status === status.value,
                      )?.bgColor,
                      color: order_status_options.find(
                        (status) => orderdetails?.status === status.value,
                      )?.textColor,
                    }}
                    className="py-[4px] text-sm leading-4 capitalize font-medium  px-2 text-center rounded-md"
                  >
                    {orderdetails?.payment_status}
                  </p>
                </div>
                <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
                  <AlertDialogTrigger>
                    <button
                      disabled={orderdetails?.payment_status === "recieved"}
                      className="rounded-lg py-[11px] disabled:opacity-40 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
                    >
                      Capture Payment
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-md animate-contentShow">
                      <form className="max-w-md">
                        <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                          <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                            Add Payment Details
                          </AlertDialogTitle>
                          <div className="flex flex-col gap-4">
                            <div className="flex gap-2 flex-shrink flex-col w-full">
                              <label
                                htmlFor="acknowledgement_no"
                                className=" text-sm font-medium leading-4"
                              >
                                Acknowledgement Number
                              </label>
                              <input
                                className="border focus:border-blue-700 p-3 rounded-lg"
                                id="acknowledgement_no"
                                type="number"
                                {...register("acknowledgement_no", {
                                  required:
                                    "Acknowledgement Number is required",
                                })}
                              />
                              {errors.acknowledgement_no && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.acknowledgement_no.message as string}
                                </p>
                              )}
                            </div>
                            <div className="flex gap-2 flex-col w-full">
                              <label
                                htmlFor="amount"
                                className=" text-sm font-medium leading-4"
                              >
                                Amount
                              </label>
                              <input
                                id="amount"
                                type="number"
                                className="border focus:border-blue-700 p-3 rounded-lg"
                                {...register("amount", {
                                  required: "Amount is required",
                                })}
                              />
                              {errors.amount && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.amount.message as string}
                                </p>
                              )}
                            </div>
                            <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                              <h3 className=" text-sm font-medium leading-4">
                                Supporting document
                              </h3>
                              <div
                                className={`py-1.5 px-3.5 justify-between rounded-md flex bg-[#F2F7FF]`}
                              >
                                <div className="flex gap-3 items-center overflow-hidden">
                                  <Img src="/images/remit/file.svg" alt="" />
                                  <p className=" text-ellipsis truncate">
                                    {selectedFile?.name || "No file selected"}
                                  </p>
                                </div>
                                <label
                                  htmlFor="supporting-document"
                                  className=" cursor-pointer text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                                >
                                  {selectedFile ? "Re-upload" : "Upload"}
                                </label>
                                <input
                                  {...register("supporting-document", {
                                    required: "Supporting document is required",
                                    onChange: handleFileChange,
                                  })}
                                  type="file"
                                  id="supporting-document"
                                  className="hidden"
                                  accept="image/*"
                                />
                              </div>
                              {errors["supporting-document"] && (
                                <p className="text-red-500 text-xs mt-1">
                                  {
                                    errors["supporting-document"]
                                      .message as string
                                  }
                                </p>
                              )}
                            </div>
                          </div>
                        </AlertDialogHeader>
                        <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                          <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                            asChild
                          >
                            <button
                              onClick={() => {
                                reset();
                                setSelectedFile(null);
                              }}
                            >
                              Close
                            </button>
                          </AlertDialogCancel>
                          <AlertDialogAction
                            type="submit"
                            onClick={handleSubmit(onSaveClick)}
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          >
                            Capture
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </form>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
              <DataTable
                isToolbarVisible={false}
                isPaginationVisible={false}
                columnsBackground="#333333"
                data={paymentData}
                columns={columns}
              />
            </section>
          </TabsContent>
          <TabsContent value="other-details">
            <div className="grid grid-cols-2 gap-8 w-full">
              <div className="w-full flex flex-col flex-grow gap-2">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Remitter Details
                </h5>
                <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name:{" "}
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.full_name}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        PAN Card:{" "}
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.pan_no}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Email:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_customers?.email}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Aadhaar Card:
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.aadhaar_no
                            ? ` **** **** ${orderdetails?._order_customers?.aadhaar_no}`
                            : "N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Mobile:
                        <span className={valueStyle}>
                          {orderdetails?._order_customers?.phone
                            ? orderdetails?._order_customers?.phone
                            : " N/A"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col flex-grow gap-2">
                <h5 className="text-[#999999] leading-4 font-medium text-base">
                  Service Provider Details
                </h5>
                <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name:{" "}
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.display_name}
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Service ID:{" "}
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.id}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        State:
                        <span className={valueStyle}>
                          {" "}
                          {
                            orderdetails?._order_service_partner
                              .legal_addr_state
                          }
                        </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        City:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.legal_addr_city}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Email:
                        <span className={valueStyle}>
                          {" "}
                          {orderdetails?._order_service_partner.email}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="w-full flex flex-col flex-grow gap-2">
                <h5>Partner Details</h5>
                <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Name: <span className={valueStyle}></span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Parner ID: <span className={valueStyle}></span>
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Mobile:
                        <span className={valueStyle}> </span>
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className={labelStyle}>
                        Emial:
                        <span className={valueStyle}></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default NewOrderDetailsPage;
