import ReactSelect from "react-select";
import { groups } from "../utils";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { setRole } from "redux/features/CreateGroup";
import { useState } from "react";
export default function SelectRole() {
  const dottedBorderWidth = "after:h-28";
  const state = useAppSelector((state) => state.CreateGroups);
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  return (
    <form className="flex flex-col gap-6" onSubmit={() => {}}>
      <p className=" text-sm leading-[18.2px] pl-10 sm:pl-0 font-medium text-[#999999]">
        Group members will have permissions & module access based on selected
        role
      </p>
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="role" className="text-sm">
            Select the role
          </label>
          <ReactSelect
            className="w-full"
            placeholder="Select any one role"
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
                height: "3rem",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#EFF4FF" : "#fff",
                color: state.isSelected ? "#165FE3" : "#333333",
                "&:hover": {
                  backgroundColor: "#EFF4FF",
                  color: "#165FE3",
                },
                padding: "14px 1rem",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
              }),
            }}
            value={groups.find((group) => group.value === state.role)}
            options={groups}
            onChange={(group) => {
              dispatch(setRole(group?.value as string));
              setDescription(group?.description as string);
            }}
            name="role"
            id="role"
          />
          <p className="text-sm font-normal text-[#333333]">{description}</p>
        </div>
      </div>
    </form>
  );
}
