import Navbar from "components/Navbar/Index";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setPipelineType } from "redux/features/CreateOpportunity";
import { ITabList, tablist } from "./utils/featureRule";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Img } from "components";
import { ILinks } from "components/DashboardNavbar";
import { useState } from "react";

export const navLinks = {
  current: {
    label: "All Overview",
    icon: "",
  },
  left: [
    {
      label: "Remit",
      href: "/remit",
      icon: "/images/overview/remit.svg",
      logo: "/images/overview/remit-text.svg",
      enabled: true,
    },
    {
      label: "Forex",
      href: "/overview",
      icon: "/images/overview/forex.svg",
      logo: "/images/overview/forex-text.svg",
      enabled: false,
    },
    // {
    //   label: "Study Abroad",
    //   href: "/overview",
    //   icon: "images/overview/study.svg",
    //   logo: "images/overview/study-text.svg",
    //   enabled: false,
    // },
    {
      label: "Corporate",
      href: "/overview",
      icon: "/images/overview/corporate.svg",
      logo: "/images/overview/corporate-text.svg",
      enabled: false,
    },
  ],
  right: [
    {
      label: "Profile",
      visible: false,
      href: "#",
      icon: "/images/overview/profile.svg",
      submenu: [
        {
          label: "My Profile",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Logout",
          href: "/logout",
          icon: "/images/overview/logout.svg",
        },
      ],
      enabled: true,
    },
    {
      label: "Help",
      visible: true,
      href: "#",
      icon: "/images/overview/wrench.svg",
      submenu: [
        {
          label: "Documents",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Youtube Tutorials",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Contact Support",
          href: "#",
          icon: "/images/sidebar_img/contact.svg",
        },
      ],
      enabled: true,
    },
    {
      label: "plus",
      visible: false,
      href: "#",
      icon: "/images/overview/btn.svg",
      submenu: [
        {
          label: "Create Deals",
          href: "/createnewdeal",
          icon: "/images/sidebar_img/remit.svg",
        },
        {
          label: "Create Orders",
          href: "/createorder",
          icon: "/images/sidebar_img/remit.svg",
        },
        {
          label: "View Orders",
          href: "/remit/orders",
          icon: "/images/sidebar_img/remit.svg",
        },
        {
          label: "Create FX Quote",
          href: "/createforexQuotation",
          icon: "/images/sidebar_img/remit.svg",
        },
        {
          label: "Create Remit Quote",
          href: "/createRemittanceQuotation",
          icon: "/images/sidebar_img/remit.svg",
        },
      ],
      enabled: true,
    },
  ],
};

export default function Opportunities() {
  const [activeLeftLink, setActiveLeftLink] = useState<string | null>(
    navLinks.current.label,
  );
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();

  const onTabChange = (value: string) => {
    dispatch(setPipelineType(value as "retail" | "corporate" | "partner"));
  };

  const handleLeftLinkClick = (label: string) => {
    setActiveLeftLink(label);
  };

  const paths: { [key: string]: string } = {
    opportunities: "Opportunities",
    "opportunities/create": "Create Opportunity",
    createcontact: "Add new Contact",
    addopportunity: "Search Contact",
    opportunitydetails: "Opportunity Details",
  };

  return (
    <div className="flex p-4 h-screen w-full flex-col flex-grow gap-2.5 font-inter">
      {/* <Navbar className="flex justify-between items-center w-full sm:hidden">
        <>
          {location.pathname === "/opportunities" ? (
            <Tabs
              className="bg-navbar-menu-bg flex rounded-lg gap-2"
              value={pipelineType}
              onValueChange={onTabChange}
            >
              <TabsList className="h-[44px]">
                {tablist
                  .filter((tab: ITabList) => true)
                  .map((tab: ITabList, index: number) => (
                    <TabsTrigger
                      disabled={!tab.enabled}
                      key={index}
                      value={tab.value}
                      className={` py-3 px-5 disabled:opacity-40 text-sm h-[44px] min-w-[120px] font-semibold ${tab.value === pipelineType ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                    >
                      {tab.label}
                    </TabsTrigger>
                  ))}
              </TabsList>
            </Tabs>
          ) : (
            <div className="flex gap-5 items-center">
              <div className="flex gap-5 items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4C6.98061 4 8.58621 5.55208 8.58621 7.46667V15.2667C8.58621 16.224 7.78341 17 6.7931 17H16.2069C17.1972 17 18 16.224 18 15.2667V7.46667C18 5.55208 16.3944 4 14.4138 4H5Z"
                    fill="#98BDFF"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 4C3.567 4 2 5.55208 2 7.46667V15.2667C2 16.224 2.7835 17 3.75 17H7.25C8.2165 17 9 16.224 9 15.2667V7.46667C9 5.55208 7.433 4 5.5 4ZM6.15625 10.9333C6.15625 10.5744 5.86243 10.2833 5.5 10.2833C5.13757 10.2833 4.84375 10.5744 4.84375 10.9333V14.4C4.84375 14.759 5.13757 15.05 5.5 15.05C5.86243 15.05 6.15625 14.759 6.15625 14.4V10.9333Z"
                    fill="#165FE3"
                  />
                  <path
                    d="M15 11.1111V8H11V11.1111C11 11.602 11.5117 12 12.1429 12H13.8571C14.4883 12 15 11.602 15 11.1111Z"
                    fill="#165FE3"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 7.5C10 7.22386 10.2985 7 10.6667 7H15.3333C15.7015 7 16 7.22386 16 7.5C16 7.77614 15.7015 8 15.3333 8H10.6667C10.2985 8 10 7.77614 10 7.5Z"
                    fill="#165FE3"
                  />
                </svg>
              </div>
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem className=" capitalize">
                    {pipelineType}
                  </BreadcrumbItem>
                  {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === pathnames.length - 1;

                    return (
                      <BreadcrumbItem key={name}>
                        {isLast ? (
                          <>
                            <BreadcrumbSeparator />
                            <BreadcrumbLink className=" capitalize">
                              {paths[name]}
                            </BreadcrumbLink>
                          </>
                        ) : (
                          <>
                            <BreadcrumbSeparator />
                            <Link to={routeTo}>
                              <BreadcrumbLink className=" capitalize">
                                {paths[name]}
                              </BreadcrumbLink>
                            </Link>
                          </>
                        )}
                      </BreadcrumbItem>
                    );
                  })}
                </BreadcrumbList>
              </Breadcrumb>
            </div>
          )}
        </>
        <ul className="flex gap-3">
          {navLinks["right"].map((linkData: ILinks, index: number) => {
            return linkData?.submenu?.length ? (
              <li
                key={index}
                aria-hidden
                className={`flex select-none h-11 justify-center items-center rounded-lg  ${linkData.label && linkData.label !== "plus" && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${linkData.label && linkData.label !== "plus" && activeLeftLink === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
                onClick={() => handleLeftLinkClick(linkData.label)}
              >
                <Popover>
                  <PopoverTrigger asChild>
                    <div className="flex justify-center items-center gap-2.5 py-3 px-4 ">
                      <Img
                        className="max-w-none"
                        style={{}}
                        src={linkData.icon}
                        alt={linkData.label}
                      />
                      {linkData?.label && linkData.visible && (
                        <span>{linkData.label}</span>
                      )}
                    </div>
                  </PopoverTrigger>
                  <PopoverContent
                    align="end"
                    side="bottom"
                    sideOffset={12}
                    className=" gap-0 w-auto p-0 will-change-[transform,opacity] animate-slideDownAndFade rounded-lg"
                  >
                    <div
                      style={{
                        boxShadow: "0px 4px 4px 0px rgba(112, 112, 112, 0.2)",
                      }}
                      className=" rounded-xl w-60 bg-white-A700 flex flex-col"
                    >
                      {linkData.submenu.map((submenu) => {
                        return (
                          <Link
                            key={crypto.randomUUID()}
                            to={submenu?.href || ""}
                            className="py-3.5 hover:bg-[#E8ECF9] font-semibold text-sm text-center items-center rounded-lg gap-3 flex px-5"
                          >
                            <Img
                              className="max-w-none"
                              src={submenu.icon}
                              alt=""
                            />
                            {submenu.label}
                          </Link>
                        );
                      })}
                    </div>
                  </PopoverContent>
                </Popover>
              </li>
            ) : (
              <li
                key={index}
                aria-hidden
                className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
                onClick={() => handleLeftLinkClick(linkData.label)}
              >
                <Img
                  className="max-w-none"
                  src={linkData.icon}
                  alt={linkData.label}
                />
                {linkData?.label && linkData.visible && (
                  <span>{linkData.label}</span>
                )}
              </li>
            );
          })}
        </ul>
      </Navbar> */}
      {/* <div className="w-fit hidden sm:flex justify-between bg-white-A700 gap-5 rounded-lg">
        <Tabs
          className=" flex rounded-lg gap-2"
          value={pipelineType}
          onValueChange={onTabChange}
        >
          <TabsList className="h-[44px]">
            {tablist
              .filter((tab: ITabList) => tab.enabled)
              .map((tab: ITabList, index: number) => (
                <TabsTrigger
                  key={index}
                  value={tab.value}
                  className={` py-3 px-5 text-sm h-[44px] min-w-[120px] font-semibold ${tab.value === pipelineType ? "border border-blue-700 bg-[#F3F8FF] text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  {tab.label}
                </TabsTrigger>
              ))}
          </TabsList>
        </Tabs>
      </div> */}
      <div className="flex-1 bg-white-A700 sm:flex-col overflow-hidden flex w-full rounded-xl p-3 border border-[#D9E0F7]">
        <Outlet />
      </div>
    </div>
  );
}
