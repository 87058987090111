import { LeadType, OpportunityType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { channel_options, close_reason_options } from "common/filters";

import { Img, Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { RowDetailsSheet } from "pages/Opportunities/opportunitydetails";
import {
  formatDate,
  getRelativeDate,
} from "pages/Opportunities/utils/featureRule";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLeadReqType, GetLeads } from "service/apis/Opportunities";

interface LeadGenericProps {
  type: string;
}
export interface ChannelOptions {
  [key: string]: { value: string; label: string; icon: string; type: string }[];
}
const columns: ColumnDef<LeadType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: "includesString",
  },
  {
    id: "contactNumber",
    accessorKey: "contactNumber",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="hidden p-0 -mx-4"
        column={column}
        title="Contact Number"
      />
    ),
    cell: ({ row }) => (
      <div className="hidden p-0 -mx-4">{row.getValue("contactNumber")}</div>
    ),
  },
  {
    id: "customer",
    accessorKey: "customer",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Customer" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" flex gap-1.5 flex-col">
          <p className="capitalize">{row.getValue("customer")}</p>
          <p className="font-normal text-sm text-[#666666]">
            {row.original?.contactNumber}
          </p>
        </div>
      );
    },
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "product",
    accessorKey: "product",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          <p className="capitalize">{row.getValue("product")}</p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row?.original?.productSubCategory}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "assignedTo",
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Assigned To" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          <p className="capitalize">{row.getValue("assignedTo")}</p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row?.original?.assignedToCity}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "channel",
    accessorKey: "channel",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Channel" />
    ),
    cell: ({ row }) => {
      const newSource = (channel_options as ChannelOptions)?.[
        (row?.original?.channelType as string)?.toLowerCase()
      ]?.find(
        (item) =>
          item.value?.toLowerCase() ===
          (row.getValue("channel") as string)
            ?.toLowerCase()
            .split(" ")
            .join(""),
      );

      return newSource?.value ? (
        <div className="grid grid-cols-1 max-w-[120px] place-content-center gap-1">
          <div className="flex items-start flex-col gap-3 justify-between w-full">
            <div className="h-full w-full flex gap-10">
              <p className="capitalize w-full">{newSource?.label}</p>
              <Img
                className="text-right h-5 w-5"
                src={newSource?.icon}
                alt="icon"
              />
            </div>
            <p className="capitalize font-normal text-sm text-[#666666]">
              {["outletID", "partnerOffice"].includes(
                row?.original?.channelSource as string,
              )
                ? ""
                : row?.original?.channelSource}
            </p>
          </div>
        </div>
      ) : (
        <p className="capitalize">{row?.original?.channelType}</p>
      );
    },
    enableSorting: false,
  },
  {
    id: "createDate",
    accessorKey: "createDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Create Date" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex w-full flex-col gap-1.5">
          <p className="w-full whitespace-nowrap">{row.original.createdAt}</p>
          <p className="capitalize font-normal text-sm text-[#666666]">
            {row.original.createdBy}
          </p>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "leadStatus",
    accessorKey: "leadStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Lead Status" />
    ),
    cell: ({ row }) => {
      const values = close_reason_options.find(
        (item) => item.value === (row.getValue("leadStatus") as string),
      );
      if (!values) {
        return (
          <p
            style={{ color: "#23AB28", backgroundColor: "#E1FFE2" }}
            className="py-[3px] px-2 text-center rounded-md"
          >
            Open
          </p>
        );
      }
      return (
        <p
          style={{ color: values?.textColor, backgroundColor: values?.bgColor }}
          className="py-[3px] px-2 text-center rounded-md"
        >
          {values?.label}
        </p>
      );
    },
    enableSorting: false,
  },
];

export default function LeadGeneric({ type }: LeadGenericProps) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<OpportunityType>();
  const { pathname } = useLocation();

  //   const filteredColumns = useMemo(() => {
  //     return columns.filter((column) => {
  //       // Add conditions to hide specific columns based on pipelineType
  //         return !["contactNumber"].includes(column?.id as string);
  //     });
  //   }, [loading]);

  const fetchLeads = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetLeadReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: "retail",
        productType: type,
      },
    };

    const name = localStorage.getItem("owner_name")?.toString();

    try {
      const resposne = (await GetLeads(payload)) as any;
      setTableData(
        resposne?.data?.map((item: any) => {
          return {
            id: item?.id,
            customer: item?._contacts?.[0]?.contactName
              ? item?._contacts?.[0]?.contactName
              : "N/A",
            product: item?._product?.name,
            productSubCategory: item?._product?.subCategory,
            channel: item?._channel_of_interaction?.[0]?.Channel,
            createdBy: item?.createdBy !== 0 ? item?._createdBy?.fname : name,
            assignedTo: item?._assignedTo?.fname,
            assignedToCity: item?._assignedTo?.city,
            leadStatus: item?.reasonToClose,
            createdAt: getRelativeDate(item?.created_at),
            contactNumber: item?._contacts?.[0]?.contactNumber
              ? item?._contacts?.[0]?.contactNumber
              : "N/A",
            channelType: item?._channel_of_interaction?.[0]?.Type,
            channelSource: item?._channel_of_interaction?.[0]?.source,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onRowClick = (row: any) => {
    setIsRowDetailsOpen(true);
    setSelectedRowData({
      uniqueId: row.id,
      id: row.id,
      name: row.customer,
      entityName: row.id,
      companyId: row.id,
      contactId: row.id,
      companyName: row.id,
      product: row.product,
      assignedTo: row.assignedTo,
      createDate: row.createdAt,
      opportunityStatus: row.id,
    });
  };

  useEffect(() => {
    fetchLeads();
  }, [type]);
  return (
    <>
      {loading && <Spinner />}
      <div
        style={{
          margin: pathname === "/remit/leads" ? "0" : "-1rem",
          padding: pathname === "/remit/leads" ? "0" : "1rem",
        }}
        className="flex h-full gap-10 py-6 px-3 sm:px-0 flex-col overflow-hidden"
      >
        {pathname !== "/remit/leads" && (
          <div className="flex w-full justify-between px-2 items-center">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize text-base">Leads</span>
            </h3>
          </div>
        )}
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          <DataTable
            columns={columns}
            data={[...tableData]}
            isToolbarVisible={true}
            onRowClick={onRowClick}
            highlightRow={isRowDetailsOpen}
            isPaginationVisible={true}
            searchData={{
              placeholder: "Search by name or mobile number",
              columns: ["id", "customer", "contactNumber", "assignedTo"],
            }}
            isPageCountVisible={true}
            defaultSorting={[{ id: "id", desc: true }]}
          />
          <RowDetailsSheet
            isOpen={isRowDetailsOpen}
            onOpenChange={setIsRowDetailsOpen}
            rowData={selectedRowData as OpportunityType}
            getOpportunityList={fetchLeads}
          />
        </div>
      </div>
    </>
  );
}
