import React from "react";
import {
  Button,
  Input,
  Radio,
  Spinner,
  Img,
  ReactTable,
  SelectBox,
  Text,
} from "components";
import { setProducts, setTotalGST } from "redux/features/CreateForexQuotation";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import {
  GetCurrencyRequestType,
  getCurrency,
  GetGSTRequestType,
  getGST,
} from "service/apis/CreateForexQuotation";
import { setGrandTotal } from "../../redux/features/CreateForexQuotation";
import { formatCurrency, formatIndianNumber } from "../../utils";
import { stat } from "fs/promises";
import { toast } from "sonner";

type ProductsTableRowType = {
  id: string | number;
  product: string;
  option: string;
  currency: string | number;
  qty: string;
  rate: string | number;
  charges: string | number;
  gst: boolean;
  total: string | number;
};

type CurrencyDataType = {
  label: string;
  value: string;
};

type Props = {
  setShowProductModal: (value: boolean) => void;
  editRowId: string;
  setEditRowId: (value: string) => void;
  setUpdateInfo: (value: boolean) => void;
  state: any;
};

const AddProduct: React.FC<Props> = ({
  setShowProductModal,
  editRowId,
  setEditRowId,
  setUpdateInfo,
  state,
}) => {
  const [currencydata, setCurrencydata] = React.useState<CurrencyDataType[]>(
    [],
  );
  const [calcGST, setCalcGST] = React.useState<string>("");

  const productOption = [
    { label: "FX Currency", value: "FX Currency" },
    { label: "FX Card", value: "FX Card" },
  ];

  React.useEffect(() => {
    setEditRowvalues();
    fetchCurrency();
  }, []);

  const fetchCurrency = () => {
    const payload: AxiosRequestConfig<GetCurrencyRequestType> = {};
    getCurrency(payload)
      .then((res) => {
        setCurrencydata(
          res.data.map((item) => {
            return {
              label: item.currency,
              value: item.currency,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  const mapperForex = {
    "9": "FX Currency",
    "16": "FX Card",
  };

  const { products, product_type } = useAppSelector(
    (state) => state.CreateForexQuotation,
  );

  const dispatch = useAppDispatch();

  const [form, setForm] = React.useState({
    product_name: mapperForex[state?.product_id] || "FX Currency",
    product_currency: "",
    product_qty: "",
    product_rate: "",
    product_charges: "",
    product_gst: false,
  });

  // React.useEffect(() => {
  //   getGst();
  // }, [form.product_qty, form.product_rate]);

  const getTotalGst = (taxable_amount: number, total_amount: number) => {
    const calcAmount = taxable_amount;

    console.log(total_amount);

    if (calcAmount > 0) {
      const auth = localStorage.getItem("authToken");
      if (auth === null) return null;
      const authToken = JSON.parse(auth);

      const payload: AxiosRequestConfig<GetGSTRequestType> = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          amount: calcAmount,
        },
      };

      getGST(payload)
        .then((res) => {
          setCalcGST(res?.data);
          if (product_type == "Encashment") {
            dispatch(
              setGrandTotal(
                Number(
                  taxable_amount -
                    (total_amount - taxable_amount) -
                    Number(res?.data),
                ),
              ),
            );
          } else {
            dispatch(setGrandTotal(Number(total_amount) + Number(res?.data)));
          }
          dispatch(setTotalGST(Number(res?.data)));
        })
        .catch((err) => {});
    } else {
      setCalcGST("");
    }
  };

  const handleOnChange = (name: string, e: string | boolean) => {
    setForm({
      ...form,
      [name]: e,
    });
  };

  const setEditRowvalues = () => {
    if (editRowId && editRowId !== "") {
      const prod = products.find(
        (item) => item.id.toString() === editRowId.toString(),
      );

      setForm({
        ...form,
        ["product_name"]: prod.product,
        ["product_currency"]: prod.currency,
        ["product_qty"]: prod.qty,
        ["product_rate"]: prod.rate,
        ["product_charges"]: prod.charges,
        ["product_gst"]: prod.gst,
      });
    }
  };

  const handleOnSubmit = () => {
    if (
      form.product_name === "" ||
      form.product_currency === "" ||
      form.product_qty === ""
      // form.product_rate === "" ||
      // form.product_charges === ""
    ) {
      toast.error("Please Complete the form");
      return;
    }

    if (editRowId && editRowId !== "") {
      const updatedProducts = products.map((item) => {
        if (item.id === editRowId) {
          // return {...item}
          return {
            ...item,
            product: form.product_name,
            currency: form.product_currency,
            qty: form.product_qty,
            rate: form.product_rate,
            charges: form.product_charges,
            gst: form.product_gst,
            total:
              Number(form.product_qty) * Number(form.product_rate) +
              Number(form.product_charges) * (form.product_gst ? 1 : 1.18),
          };
        } else {
          return item;
        }
      });
      const taxable_amount = Number(
        updatedProducts.reduce(
          (sum, obj) => sum + Number(obj.qty) * Number(obj.rate),
          0,
        ),
      );
      const total_amount = Number(
        updatedProducts.reduce((sum, obj) => sum + Number(obj.total), 0),
      );
      getTotalGst(taxable_amount, total_amount);

      dispatch(setProducts(updatedProducts));
      setUpdateInfo(true);
      const timer = setTimeout(() => {
        setUpdateInfo(false);
      }, 3000);
      setEditRowId("");
    } else {
      const newRow = {
        id:
          products.length > 0
            ? Number(products[products.length - 1].id) + 1
            : 1,
        product: form.product_name,
        currency: form.product_currency,
        qty: form.product_qty,
        rate: form.product_rate,
        charges: form.product_charges ? form.product_charges : 0,
        gst: form.product_gst,
        total:
          Number(form.product_qty) * Number(form.product_rate) +
          Number(form.product_charges) * (form.product_gst ? 1 : 1.18),
      };
      const updatedProducts = [...products, newRow];
      const taxable_amount = Number(
        updatedProducts.reduce(
          (sum, obj) => sum + Number(obj.qty) * Number(obj.rate),
          0,
        ),
      );
      const total_amount = Number(
        updatedProducts.reduce((sum, obj) => sum + Number(obj.total), 0),
      );
      getTotalGst(taxable_amount, total_amount);
      dispatch(setProducts(updatedProducts));
    }

    setShowProductModal(false);
  };

  return (
    <div className=" bg-white-A700 w-2/3 rounded-xl md:w-[calc(100vh-30px)] z-50 md:h-[calc(100vh-200px)]  overflow-hidden">
      <div className="flex flex-col w-full h-full">
        <div className="py-5 px-10 bg-blue_gray-lite-bg rounded-xl">Forex</div>
        <div className="py-5">
          <div className="flex flex-col px-10 gap-2">
            <Text className="text-lg font-bold leading-[25px]">
              Add the product details
            </Text>
            <Text className="text-sm font-medium leading-4">
              Fill the required details
            </Text>
          </div>
        </div>
        <div className=" py-8 px-10 flex flex-grow justify-between overflow-auto h-full w-full gap-10 md:flex-col md:gap-4">
          <div className=" w-full pl-5 flex flex-col gap-5">
            <div className="flex relative flex-col w-full gap-3">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                Select the Product you want to quote.
              </Text>
              <SelectBox
                className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-lg text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="placeholder:text-black-900_99 h-full"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={mapperForex[state?.product_id] || "FX Currency"}
                onChange={(e: string) => {
                  handleOnChange("product_name", e);
                }}
                isMulti={false}
                name="inputbox"
                options={productOption}
                isSearchable={true}
                placeholder="Select Product Name"
              />
            </div>
            <div className="flex relative flex-col w-full gap-3">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm sm:text-xs font-medium leading-[17px]">
                Enter the Currency and Qty you want to send.
              </Text>
              <div className="flex flex-row w-full gap-[5px]">
                <SelectBox
                  className="bg-white-A700 border text-blue-700 font-medium focus-within:border-blue-700 py-4 px-[15px] rounded-lg text-[15px] text-left tracking-[0.50px] w-1/2"
                  placeholderClassName="placeholder:text-black-900_99 h-full"
                  indicator={
                    <Img
                      className="h-5 w-5 pt-1"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  value={form.product_currency}
                  onChange={(e: string) =>
                    handleOnChange("product_currency", e)
                  }
                  isMulti={false}
                  name="inputbox"
                  options={currencydata}
                  isSearchable={true}
                  placeholder="Currency"
                />
                <Input
                  name="input_One"
                  type="number"
                  value={form.product_qty}
                  onChange={(e: string) => handleOnChange("product_qty", e)}
                  placeholder="Enter Product Qty"
                  className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                  wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                ></Input>
              </div>
            </div>
            <div className="flex relative flex-col w-full gap-3">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                Enter the Rate.
              </Text>
              <Input
                name="input_One"
                value={form.product_rate}
                type="number"
                onChange={(e: string) => handleOnChange("product_rate", e)}
                placeholder="Enter Product Rate"
                className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex relative flex-col w-full gap-3">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src={`images/overview/ellipse.svg`}
                  alt=""
                />
              </div>
              <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                Enter the Product Service Charge
              </Text>
              <Input
                name="input_One"
                type="number"
                value={form.product_charges}
                onChange={(e: string) => handleOnChange("product_charges", e)}
                placeholder="Enter Product Service Charge"
                className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-row w-full items-center gap-3">
              <input
                type="checkbox"
                className=""
                checked={form.product_gst}
                onClick={() => {
                  handleOnChange(
                    "product_gst",
                    form.product_gst ? false : true,
                  );
                }}
              ></input>
              <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                This Service Charge includes GST
              </Text>
            </div>
          </div>
          <div className="flex flex-col p-5 gap-5 rounded-xl bg-[#FBFCFF] w-full">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row w-full justify-start">
                <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                  Amount
                </Text>
              </div>
              <div className="flex flex-row w-full justify-end">
                <Text className="text-base leading-4 font-normal">
                  {formatCurrency(
                    Number(
                      (
                        Number(form.product_qty) * Number(form.product_rate)
                      )?.toFixed(2),
                    ),
                    "INR",
                  )}
                </Text>
              </div>
            </div>
            <div className="flex flex-row w-full items-center border-b pb-3 border-[#98BDFF]">
              <div className="flex flex-row w-full justify-start">
                <Text className="text-sm sm:text-xs  font-medium leading-[17px]">
                  Services Charges (Incl. GST)
                </Text>
              </div>
              <div className="flex flex-row w-full justify-end">
                <Text className="text-base leading-4 font-normal">
                  {formatCurrency(
                    Number(
                      (
                        Number(form.product_charges) *
                        (form.product_gst ? 1 : 1.18)
                      )?.toFixed(2),
                    ),
                    "INR",
                  )}
                </Text>
              </div>
            </div>
            <div className="flex flex-row border border-blue-700 rounded-lg p-3 w-full items-center">
              <div className="flex flex-row w-full justify-start">
                <Text className="text-sm  sm:text-xs font-medium leading-[17px]">
                  Product Total
                </Text>
              </div>
              <div className="flex flex-row w-full justify-end">
                <Text className="text-base leading-4 text-blue-700 font-normal">
                  {formatCurrency(
                    Number(
                      (
                        Number(form.product_qty) * Number(form.product_rate) +
                        Number(form.product_charges) *
                          (form.product_gst ? 1 : 1.18)
                      )?.toFixed(2),
                    ),
                    "INR",
                  )}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 py-5 px-10 bg-blue_gray-lite-bg rounded-xl">
          <Button
            onClick={() => {
              setShowProductModal(false);
              setEditRowId("");
            }}
            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
          >
            Cancel
          </Button>
          <Button
            disabled={
              !form.product_name  ||
              !form.product_currency||
              !form.product_qty ||
              !form.product_rate
            }
            onClick={() => handleOnSubmit()}
            className="rounded-lg disabled:opacity-40 border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddProduct;
